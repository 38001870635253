import * as React from "react";
import { LayoutProvider, Wrapper } from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import { useViewPortSize } from "../hooks";
import { ResponsiveContainer } from "../atoms/Containers";
import { stackStyled } from "../theme";
import { ErrorMessage } from "../components/sections";

//Query
const query = graphql`
  query {
    strapiErrorMessage404 {
      title
      errorMessage
      action
      href
      media {
        url
        ext
      }
    }
  }
`;

//Interface

interface IErrorMessage {
  strapiErrorMessage404: {
    title: string;
    errorMessage: string;
    action: string;
    href: string;
    media: {
      url: string;
      ext: string;
    };
  };
}

//Styled components
const MainWrapper = stackStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: "flex",
    maxWidth: "1280px",
    width: props.isMobile ? "95%" : props.isTablet ? "90%" : "100%",
    justifyContent: "center",
    padding: props.isMobile ? "50px 0px 50px 0px" : "50px 0px 100px 0px",
    marginLeft: "auto",
    marginRight: "auto",
    overflowX: "hidden",
    textAlign: "center",
  })
);

const NotFoundPage = (props: IErrorMessage) => {
  //Hooks
  const data = useStaticQuery<IErrorMessage>(query);
  const [isMobile, isTablet] = useViewPortSize();

  return (
    <LayoutProvider seo={false}>
      <MainWrapper isMobile={isMobile}>
        <ResponsiveContainer flexDirection="column" bottomLeft={false}>
          <ErrorMessage
            title={data.strapiErrorMessage404.title}
            errorMessage={data.strapiErrorMessage404.errorMessage}
            action={data.strapiErrorMessage404.action}
            href={data.strapiErrorMessage404.href}
            mediaSrc={data.strapiErrorMessage404.media.url}
            mediaExt={data.strapiErrorMessage404.media.ext}
          />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default NotFoundPage;
